<template>
  <el-dialog title="商户设置"
             width="60%"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="商户号"
                        prop="merchant_num">
            <el-input v-model="form.merchant_num"
                      placeholder="请输入商户号"
                      :disabled="form.id?true:false"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="微信商户号"
                        prop="wx_merchant_num">
            <el-input v-model="form.wx_merchant_num"
                      placeholder="请输入微信商户号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商户名称"
                        prop="name">
            <el-input v-model="form.name"
                      placeholder="请输入商户名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商户类型"
                        prop="type">
            <el-select class="width100"
                       v-model="form.type"
                       placeholder="请选择">
              <el-option v-for="item in merchantTypeList"
                         :key="item"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人"
                        prop="contact">
            <el-input v-model="form.contact"
                      placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="所属企业手机号码"
                        prop="user_id">
            <el-select class="width100"
                       v-model="form.user_id"
                       filterable
                       remote
                       placeholder="请输入关键词"
                       :remote-method="remoteMethod"
                       :loading="selectLoading">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="resetForm('form')"
                 v-if="!form.user_id">重置</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">{{form.user_id?'修改':'立即创建'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

import { setMerchant, fetchCompanies } from '@/api/merchant'
export default {
  props: {
  },
  data () {
    return {
      form: {
        wx_merchant_num: '', // 微信商户号
        merchant_num: '', // 商户号
        name: '', // 商户名称
        type: '', // 商户类型
        contact: '', // 联系人
        user_id: ''
      },
      merchantTypeList: ['微信邮付商户', '其他微信支付商户'], // 商户类型列表
      rules: {
        merchant_num: [
          { required: true, message: '请输入商户号', trigger: 'blur' }
        ],
        wx_merchant_num: [
          { required: true, message: '请输入微信商户号', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择商户类型', trigger: 'change' }
        ],
        contact: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        user_id: [
          { required: true, message: '请选择所属企业手机号码', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false
    }
  },
  components: {
  },
  methods: {
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchCompanies({
          page: 1,
          size: 5,
          search_type: 'G_TEL',
          search_keyword: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          const list = []
          res.data.generals.map((item) => {
            list.push({
              value: item.id,
              label: item.tel,
              name: item.name
            })
          })
          this.options = list
        }
      } else {
        this.options = []
      }
    },
    handleClose () { },
    showModel (formData) {
      this.dialogVisible = true
      if (formData) {
        this.form = Object.assign({}, formData)
        this.form.merchant_id = formData.id
        this.remoteMethod(formData.tel)
      } else {
        this.form = {
          wx_merchant_num: '', // 微信商户号
          merchant_num: '', // 商户号
          name: '', // 商户名称
          type: '', // 商户类型
          contact: '', // 联系人
          user_id: ''
        }
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setMerchant(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
