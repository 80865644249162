<template>
  <div class="container">

    <table-page dataName="merchants"
                :search="search"
                ref="table"
                :request="fetchMerchants">
      <template slot="button">
        <el-button type="primary"
                   class="mt-15"
                   @click="addMerchant">新增商户</el-button>
      </template>

      <el-table-column prop="merchant_num"
                       label="商户号">
      </el-table-column>
      <el-table-column prop="wx_merchant_num"
                       label="微信商户号">
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="商户名称">
      </el-table-column>
      <el-table-column prop="tel"
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="store_total"
                       label="门店数">
      </el-table-column>
      <el-table-column prop="store_normal_total"
                       label="认证门店数">
      </el-table-column>
      <el-table-column prop="activity_total"
                       label="关联活动数">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="editMerchant(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="delMerchant(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <set-merchant ref="form"
                  @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import { fetchMerchants, delMerchant } from '@/api/merchant'
import SetMerchant from './components/SetMerchant'
export default {
  name: 'Merchants',
  data () {
    return {
      fetchMerchants,
      search: [{
        type: 'select',
        key: 'search_type',
        name: '搜索类型',
        value: '',
        list: [
          {
            value: 'MERCHANT_NUM',
            label: '商户号'
          },
          {
            value: 'NAME',
            label: '商户名称'
          },
          {
            value: 'G_TEL',
            label: '归属企业手机号码'
          }
        ]
      }, {
        type: 'input',
        value: '',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }]
    }
  },
  components: {
    TablePage,
    SetMerchant
  },
  methods: {
    addMerchant () {
      this.$refs.form.showModel()
    },
    editMerchant (row) {
      this.$refs.form.showModel(row)
    },
    delMerchant (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delMerchant({
          merchant_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
